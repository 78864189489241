import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { BuybackOnLot, GenericBuybackLot, GenericLot } from '../../../shared/models/generic-lot';
import { Catalog, CatalogsRequest } from '../../../shared/models/catalog';
import { Clock } from '../../../shared/models/clock';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class AuctionSupplyMonitorService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getLots(auctionClusterId: number, catalogId: number, date: string, genericFilters: string = null, clockId?: number): Observable<GenericLot> {
    let url = `${this.apiPath}/${auctionClusterId}/catalog/${catalogId}/date/${date}/supplymonitor`;
    if (clockId && clockId !== 0) {
      url += `?clockId=${clockId}`;
      if (genericFilters != null) {
        url += `&genericFilters=${genericFilters}`;
      }
    }
    else if (genericFilters != null) {
      url += `?genericFilters=${genericFilters}`;
    }
    return this.webApiService.getList(url);
  }

  getUngroupedLots(auctionClusterId:number, catalogId: number, date: string, lotIds: Array<number>): Observable<GenericBuybackLot> {
    let url = `${this.apiPath}/${auctionClusterId}/catalog/${catalogId}/date/${date}/ungroupedlots/`;
    return this.webApiService.save(url, { 'ids': lotIds });
  }

  getCatalogsForPermissions(auctionClusterId: number, catalogsRequest: CatalogsRequest): Observable<Array<Catalog>> {
    return this.webApiService.save(this.apiPath + '/' + auctionClusterId + '/auction/catalog/permission', catalogsRequest);
  }

  getClocksByCatalog(auctionClusterId: number, catalogId: number, permissionIds: Array<number>): Observable<Array<Clock>> {
    return this.webApiService.save(`${this.apiPath}/${auctionClusterId}/auction/catalog/${catalogId}/clock`, { 'ids': permissionIds });
  }

  insertBuyback(buyback: BuybackOnLot, auctionClusterId: number, catalogId: number): Observable<any> {
    let url = `${this.apiPath}/${auctionClusterId}/catalog/${catalogId}/lot/buyback/`;
    return this.webApiService.save(url, buyback);
  }

  editBuyback(buyback: BuybackOnLot, auctionClusterId: number, catalogId: number): Observable<any> {
    let url = `${this.apiPath}/${auctionClusterId}/catalog/${catalogId}/lot/buyback/`;
    return this.webApiService.put(url, buyback);
  }

  deleteBuyback(buyback: BuybackOnLot, auctionClusterId: number, catalogId: number): Observable<any> {
    return this.webApiService.deleteSingle(`${this.apiPath}/${auctionClusterId}/catalog/${catalogId}/lot/buyback`, buyback);
  }

}
