export class TicketPrinter {
  ticketPrinterId: number;
  auctionClusterId: number;
  name: string;
  routingSubKey: string;
  status: number;
  ticketType: number;
  enabled: boolean;
  useAllLocations: boolean;
  useAsSystemFieldPrinter: boolean;
  locations: Array<number> = [];
  clocks: Array<number> = [];
  catalogs: Array<number> = [];
  printTiming: number;
  printingDelay: number;
}

export enum PrinterStatus {
  'DISCONNECTED' = 0,
  'CONNECTED' = 1,
  'UNKOWN' = 2,
  'OUTOFPAPER' = 3,
  'PRINTHEADOPEN' = 4,
  'PRINTHEADERROR' = 5,
  'SENSORERROR' = 6,
  'CUTTERERROR' = 7,
  'OUTOFMEMORY' = 8,
  'NETWORKERROR' = 9,
  'RIBBONERROR' = 10
}

export enum TicketPrinterTypes {
  'SUPPLY_TICKET' = 1,
  'TRANSACTION_TICKET' = 2
}

export enum TicketPrinterPrintTiming {
  'USE_DEFAULT_CONFIGURATION' = 0, 
  'PRINT_DIRECTLY' = 1, 
  'PRINT_AFTER_DELAY' = 2, 
  'PRINT_ON_CONFIGURED_IO' = 3
}
