import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

// components
import { ContentComponent } from '../../shared/content/content.component';

// models
import { AccessLevel, AdditionalMenuItem } from '../../shared/models/additional-menu-item';

// services
import { LanguageService } from '../../shared/services/language.service';

import { AuctionClusterService } from '../../platform/shared/services/auction-cluster.service';

@Component({
  selector: 'auction-content.component',
  templateUrl: './auction-content.component.html',
  styleUrls: ['./auction-content.component.scss']
})

export class AuctionContentComponent extends ContentComponent implements OnInit, OnDestroy {
  myRoute: any;
  navigationSubscription: any;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  menuItems: Array<AdditionalMenuItem> = [];
  subMenuItems: Array<AdditionalMenuItem> = [];

  constructor(
    injector: Injector,
    private auctionClusterService: AuctionClusterService,
    public languageService: LanguageService,
    private titleService: Title,
    route: ActivatedRoute) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(res => {
      this.rtlEnabled = res;
    });

    this.myRoute = route;
    //this.navigationSubscription = this.router.events.subscribe((e: any) => {
    //  // If it is a NavigationEnd event re-initalise the component
    //  if (e instanceof NavigationEnd) {
    //    this.initialise();
    //  }
    //});
  }

  initialise(){ 
    this.auctionClusterService.getAuctionClusters().subscribe(acs => {
      this.sidebarFirstLogo = null;
      this.sidebarSecondLogo = null;
      if (acs && acs.length > 0) {
        if (document.URL.indexOf('auction') > -1) {
          const acId = +this.myRoute.snapshot.params['id'] || +this.myRoute.snapshot.firstChild.params["id"];
          const ac = acs.find(a => a.auctionClusterId === acId);
          if (ac) {
            this.manageLocaleStorageLogoImages(ac.image, ac.secondLogo);
            if (ac.pageTitle) {
              this.titleService.setTitle(ac.pageTitle);
            }
            if (ac.favicon){
              const favicon = document.getElementById('faviconchangable') as HTMLLinkElement;
              if (favicon) {
                favicon.href = ac.favicon;
              }
            }
          } else {
            this.removeLocalStorageLogoImages();
          }
        } else {
          this.manageLocaleStorageLogoImages(acs[0].image, acs[0].secondLogo);
        }
      } else {
        this.removeLocalStorageLogoImages();
      }

      const firstLogo = localStorage.getItem('AUCXIS_SIDEBAR_LOGO');
      const secondLogo = localStorage.getItem('AUCXIS_SIDEBAR_SECOND_LOGO');

      if (firstLogo) {
        this.sidebarFirstLogo = firstLogo;
      }
      if (secondLogo) {
        this.sidebarSecondLogo = secondLogo;
      }
    });
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  ngOnInit() {
    this.initialise();

    super.ngOnInit();

    this.additionalMenuItemsService.getPublicMenuItems().subscribe(result => {

      if (result !== undefined){
        result.forEach( item => {
          if (item.accessLevels.length > 0){
            item.accessLevels.forEach(_ => {
              if (_ === AccessLevel.AUCTION_CLUSTER) {
                if (item.submenuId !== null) {
                  this.subMenuItems.push(item);
                } else this.menuItems.push(item);
              }
            })
          }
        })

        this.menuItems.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0));
        this.subMenuItems.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0));
      }
    },
      error => {
        this.errorService.show(error);
      });
  }

  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialise()
    // method on every navigationEnd event.
    if (this.navigationSubscription) {
       this.navigationSubscription.unsubscribe();
    }
    this._subscription.unsubscribe();
  }
}
