import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { Moment } from 'moment';
import * as _moment from 'moment';
import { map, Subscription } from 'rxjs';
import { forkJoin } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

// components
import { BuyerSupplyMatrixLotsComponent } from './buyer-supply-matrix-lots.component';

// models
import { ApplicationSettings } from '../../shared/models/application-settings';
import { AuctionCluster } from '../../shared/models/auction-cluster';
import { Catalog, CatalogsRequest } from '../../shared/models/catalog';
import { DateRanges, Report, SystemReportDesignTypes } from '../../shared/models/report';
import { GridProperty, ProductProperty } from '../../shared/models/product';
import { LookupTable, LookupTableField } from '../../auction/shared/models/lookup-table';
import { LotProperty, Lot } from '../../shared/models/lot';
import { MasterData } from '../../shared/models/master-data';
import { ProductPropertyTypeEnum } from '../../shared/enum/productPropertyTypeEnum';
import { ReportFilterModel } from '../../auction/shared/models/report-filter-model';
import { UserPermissions, BuyerPermissionEnum } from '../../shared/models/user-permissions';

import { BuyerClock } from '../shared/models/buyer-level-clocks';
import { BuyerLevelSubbuyer } from '../shared/models/buyer-level-subbuyer';
import { SupplyMatrixLot } from '../shared/models/supply-matrix-lot';

// services
import { TranslateService } from '@ngx-translate/core';
import { ServiceFactory } from '../../shared/factory/service-factory';
import { AuthService } from '../../shared/services/auth.service';
import { ErrorService } from '../../shared/services/error.service';
import { LanguageService } from '../../shared/services/language.service';
import { SpinnerService } from '../../shared/services/spinner.service';
import { TitleService } from '../../shared/services/title.service';
import { TokenService } from '../../shared/services/token.service';
import { WebApiService } from '../../shared/services/web-api.service';

import { AuctionClusterService } from '../shared/services/auction-cluster.service';
import { BuyerLevelReportService } from '../shared/services/report.service';
import { CatalogService } from '../shared/services/catalog.service';
import { ProductService } from '../shared/services/product.service';
import { LookupTableService } from '../shared/services/lookup-table.service';
import { MasterDataService } from '../shared/services/master-data.service';
import { SupplyMatrixService } from '../shared/services/supply-matrix.service';

const moment = (_moment as any).default ? (_moment as any).default : _moment;
const NOON = 12;

@Component({
  selector: 'buyer-supply-matrix-component',
  templateUrl: 'buyer-supply-matrix.component.html',
  styleUrls: ['./buyer-supply-matrix.component.scss']
})
export class BuyerSupplyMatrixComponent implements OnInit, OnDestroy {

  auctionClusterService: AuctionClusterService;
  catalogService: CatalogService;
  lookupTableService: LookupTableService;
  masterDataService: MasterDataService;
  productService: ProductService;

  buyerId: number;
  auctionClusters: Array<AuctionCluster> = [];
  auctionClusterId: number;
  catalogs: Array<Catalog> = [];
  catalogId: number;
  prebidsEnabled = false;
  clocks: Array<BuyerClock> = [];
  date = new Date();
  lots: Array<SupplyMatrixLot> = [];
  suppliers: Array<string> = [];
  groups: Array<string> = [];
  suppliersNew: Array<any> = [];
  articlesNewAxisOne: Array<any> = [];
  articlesNewAxisTwo: Array<any> = [];
  cellData = [];
  sumsForArticlesAxisOne = [];
  sumsForArticlesAxisTwo = [];
  sumsForSuppliers = [];
  totalSum: number;
  interval: boolean;
  reversed = false;
  lastRefresh = '';
  matrix = [];
  matrixNew = [];
  permissions: UserPermissions;
  subbuyers: Array<BuyerLevelSubbuyer> = [];
  JSON: any = JSON;
  translate: any;
  dateFilterItems = [];

  genericFilters = '';

  productId: number;
  reportId: number;
  report: Report = new Report();
  allReports: Array<Report> = [];
  productProperties: Array<ProductProperty> = [];
  reportProperties: Array<GridProperty> = [];

  masterDataDropdowns: Array<any> = [];
  masterDataDropdownsFiltered: Array<any> = [];
  dropdownsData: Array<any> = [];
  comboBoxes: Array<any> = [];
  lookupTables: Array<LookupTable> = [];

  auctionId: number = null;

  reportName: string = '';


  filters: Array<any> = [];
  filterBindings: ReportFilterModel = new ReportFilterModel();
  dateFiltersCascadingDefinitions: any = {};

  newReportName: string;
  isSaveProfileDialogOpened: boolean;
  isLoadProfileDialogOpened: boolean;

  @ViewChild('detailsForm') detailsForm: any;
  @ViewChild('lots') buyerSupplyMatrixLotsComponent: BuyerSupplyMatrixLotsComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    protected appSettings: ApplicationSettings,
    protected route: ActivatedRoute,
    protected webApiService: WebApiService,
    private titleService: TitleService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private spinner: SpinnerService,
    private reportService: BuyerLevelReportService,
    private authenticationService: AuthService,
    private dataService: SupplyMatrixService,
    private errorService: ErrorService,
    private tokenService: TokenService
  ) {
    this.getServices(route, appSettings, webApiService);

    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
    translateService.get(['TODAY', 'YESTERDAY', 'LAST_WEEK', 'RANGE']).subscribe((res: string) => {
      this.dateFilterItems = [
        {
          value: 0,
          name: res['TODAY']
        },
        {
          value: 1,
          name: res['YESTERDAY']
        },
        {
          value: 2,
          name: res['LAST_WEEK']
        },
        {
          value: 3,
          name: res['RANGE']
        }
      ];
    });
  }

  ngOnInit() {
    this.titleService.set('SUPPLY_MATRIX.TITLE');
    this.buyerId = +this.route.snapshot.params['id'];

    if (!this.permissions) {
      this.permissions = this.tokenService.getCurrentUserPermissions();
    }

    this.spinner.show();

    this.auctionClusterService.getAuctionClustersPerLevelWithoutImage().subscribe(ac => {
      this.auctionClusters = ac;
      if (this.auctionClusters.length === 1) {
        this.auctionClusterId = this.auctionClusters[0].auctionClusterId;
        this.onAuctionClusterChange();

        this.getReportTemplates();
      }
      this.spinner.hide();
    }, error => {
      this.errorService.show(error);
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    if (this.interval) {
      this.interval = false;
    }
    this._subscription.unsubscribe();
  }

  getServices(route, appSettings, webApiService) {
    this.auctionClusterService = ServiceFactory.getAuctionClusterService(document.location.hash, route, appSettings, webApiService) as AuctionClusterService;
    this.catalogService = ServiceFactory.getCatalogService(route, appSettings, webApiService) as CatalogService;
    this.lookupTableService = ServiceFactory.getLookupTableService(route, appSettings, webApiService) as LookupTableService;
    this.masterDataService = ServiceFactory.getMasterDataService(document.location.hash, route, appSettings, webApiService) as MasterDataService;
    this.productService = ServiceFactory.getProductService(document.location.hash, route, appSettings, webApiService) as ProductService;
  }

  hasBuyOnClockPermission() {
    if (!this.permissions) {
      this.permissions = this.tokenService.getCurrentUserPermissions();
    }

    if (this.permissions.BuyerPermissions.find(p =>
      p.Key === this.buyerId && p.Value && p.Value.length > 0 && p.Value.filter(v => v === BuyerPermissionEnum.BuyOnClock).length > 0)) {
      return true;
    }

    return false;
  }

  onAuctionClusterChange() {
    this.catalogId = null;
    if (this.interval) {
      this.interval = false;
    }
    if (this.auctionClusterId == null) {
      return;
    }
    
    var catalogsRequest = new CatalogsRequest();
    catalogsRequest.salesDate = this.date;
    catalogsRequest.isTransaction = false;

    this.spinner.show();
    forkJoin(
      this.catalogService.getAllCatalogsWithSupplyOrTransactions(catalogsRequest),
      this.dataService.getSubbuyers(this.buyerId, this.auctionClusterId)
    ).subscribe(result => {
      this.catalogs = result[0];
      if (this.catalogs.length > 0) {
        this.catalogId = this.catalogs[0].catalogId;
        this.onCatalogChange();
      }
      this.subbuyers = result[1];
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  onCatalogChange() {
    if (this.catalogId == null) {
      return;
    }
    var catalog = this.catalogs.find(_ => _.catalogId == this.catalogId);
    if (catalog) {
      this.prebidsEnabled = catalog.prebid !== 0;
    }
    if (this.interval) {
      this.interval = false;
    }
  }

  onClockChange() {
    if (this.interval) {
      this.interval = false;
    }
  }

  parseArticles(articles) {
    const newArticles = [];

    articles.forEach(a => {
      let groupSuppliesTranslated = [];
      a.groupSupply.forEach(gs => {
        let translatedText = this.languageService.getTranslatableText(gs.trim());
        groupSuppliesTranslated.push(translatedText.trim());
      });
      let groupSupplyTranslated = groupSuppliesTranslated.join(', ');
      // Trim concatenated translations and remove leading and trailing comas
      groupSupplyTranslated = groupSupplyTranslated.replace(/(^[,\s]+)|([,\s]+$)/g, '');

      newArticles.push({
        groupSuppliesTranslated: groupSuppliesTranslated,
        articleId: a.articleId
      });
    });

    return newArticles;
  }

  buildMatrixNew() {
    const newMatrix = [];
    this.suppliersNew.forEach(s => {
      const row = [];
      this.articlesNewAxisOne.forEach(a => {
        const supplierData = this.cellData[s.supplierId];
        if (supplierData) {
          const lot = supplierData[a.articleId];

          if (lot) {
            row.push({
              amount: lot.a,
              isForecast: lot.f,
              lotIds: lot.l,
              hasMarkOrPrebid: lot.m
            });
          } else {
            row.push({
              amount: -1
            });
          }
        } else {
          row.push({
            amount: -1
          });
        }
      });
      newMatrix.push(row);
    });
    this.matrixNew = newMatrix;
  }

  buildMatrixNewWithTwoAxes() {
    const newMatrix = [];
    this.articlesNewAxisTwo.forEach(s => {
      const row = [];
      this.articlesNewAxisOne.forEach(a => {
        const axisTwoData = this.cellData[s.articleId];
        if (axisTwoData) {
          const lot = axisTwoData[a.articleId];

          if (lot) {
            row.push({
              amount: lot.a,
              isForecast: lot.f,
              lotIds: lot.l,
              hasMarkOrPrebid: lot.m
            });
          } else {
            row.push({
              amount: -1
            });
          }
        } else {
          row.push({
            amount: -1
          });
        }
      });
      newMatrix.push(row);
    });
    this.matrixNew = newMatrix;
  }

  dateChanged(dateTime: Moment) {
    if (this.interval) {
      this.interval = false;
    }
    this.date = moment(dateTime).toDate();
    this.onAuctionClusterChange();
  }

  filterData() {
    this.suppliers = [];
    this.groups = [];
    this.getData();
    this.interval = true;
  }

  onLotsClosed() {
    this.getData();
  }

  getData() {
    this.spinner.show();
    const dateFormatted = moment(this.date).local().format('YYYY-MM-DD');

    // Build filters for report
    this.filterReport();

    this.dataService.getLotsNew(this.buyerId, this.auctionClusterId,
      this.catalogId, dateFormatted, null, this.translateService.currentLang, this.genericFilters).subscribe(newLots => {
        this.spinner.hide();

        this.lastRefresh = moment().locale(this.translateService.currentLang).format('DD.MM.YYYY. HH:mm');
        if (newLots) {
          if (newLots.suppliers)
            this.suppliersNew = newLots.suppliers;
          else
            this.articlesNewAxisTwo = this.parseArticles(newLots.articlesAxisTwo);
          this.articlesNewAxisOne = this.parseArticles(newLots.articlesAxisOne);
          this.cellData = newLots.cellData;
          this.sumsForArticlesAxisOne = newLots.sumsForArticlesAxisOne;
          this.sumsForArticlesAxisTwo = newLots.sumsForArticlesAxisTwo;
          this.sumsForSuppliers = newLots.sumsForSuppliers;
          this.totalSum = newLots.totalSum;
          if (newLots.suppliers)
            this.buildMatrixNew();
          else
            this.buildMatrixNewWithTwoAxes();
        } else {
          this.suppliersNew = [];
          this.articlesNewAxisOne = [];
          this.articlesNewAxisTwo = [];
          this.cellData = [];
          this.sumsForArticlesAxisOne = [];
          this.sumsForArticlesAxisTwo = [];
          this.sumsForSuppliers = [];
          this.totalSum = 0;

          // this.buildMatrixNew();
        }
      }, err => {
        this.spinner.hide();
      });
  }


  openPrebidPopupEditorNew(supplierId, articleId) {
    const supplierData = this.cellData[supplierId];
    if (supplierData) {
      const lot = supplierData[articleId];

      if (lot) {
        this.buyerSupplyMatrixLotsComponent.open(this.auctionClusterId, this.catalogId,
          this.date, lot.l, this.subbuyers);
      }
    }
  }
  
  getSumSupplierNew(supplierId: number) {
    let sum = this.sumsForSuppliers[supplierId];
    return sum ? sum : 0;
  }

  getSumGroupNewAxisOne(articleId: number) {
    let sum = this.sumsForArticlesAxisOne[articleId];
    return sum ? sum : 0;
  }

  getSumGroupNewAxisTwo(articleId: number) {
    let sum = this.sumsForArticlesAxisTwo[articleId];
    return sum ? sum : 0;
  }

  getTotalSumNew() {
    return this.totalSum;
  }

  private getTranslation(value: string) {
    if (!value) {
      return '';
    }
    if (!this.IsJsonString(value)) {
      return value.replace(/"/g, "'");
    }
    const translated = this.languageService.getTranslatableText(value);
    return translated;
  }

  private IsJsonString(str: string) {
    try {
      const o = JSON.parse(str);
      if (o && typeof o === 'object') {
        return true;
      }
    } catch (e) {
      //
    }
    return false;
  }

  getReportTemplates() {
    this.reportService.getReportsBySystemReportDesignType(this.buyerId, SystemReportDesignTypes.BuyerSupplyMatrixFilter).subscribe(result => {
      this.allReports = result;
      if (this.allReports.length !== 0) {
        let report = this.allReports.find(_ => _.systemDefault);
        if (report) {
          this.applyReportFilters(report);
        }
        else {
          this.getDefaultReport();
        }
      }
      else {
        this.getDefaultReport();
      }
    }, error => {
      this.errorService.show(error);
      this.getDefaultReport();
    });
  }

  getDefaultReport() {
    this.reportService.getReportBySystemReportDesignType(this.buyerId, SystemReportDesignTypes.BuyerSupplyMatrixFilter).subscribe(result => {
        this.applyReportFilters(result);
      });
  }

  applyReportFilters(report: Report) {
    this.filters = [];

    if (!report || report.reportId == 0)
      return;

    this.report = report;
    this.reportId = report.reportId;
    this.reportName = this.report.description;
    this.productId = this.report.reportDesign.productId;

    forkJoin(
      this.productService.getProductProperties(this.productId),
      this.productService.getReportProperties(this.productId, this.report.reportDesignId)
    ).subscribe((result: Array<any>) => {
      this.productProperties = result[0];
      this.reportProperties = result[1];
      this.getMasterDataListsData();
    },
      error => {
        this.errorService.show(error);
      });
  }

  getMasterDataListsData() {
    const masterDataLists: Array<MasterData> = new Array<MasterData>();

    const masterDataListIds: Array<number> = [];

    this.reportProperties.forEach(reportProperty => {
      const productProperty = this.productProperties.filter(x => x.productPropertyId === reportProperty.productPropertyId)[0];
      const productPropertyTypeId = productProperty.propertyTypeId;
      const productPropertyMasterDataListId = productProperty.masterDataListId;
      // We show only date and master data property types.
      if (productPropertyTypeId === ProductPropertyTypeEnum.DATE || productPropertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
        if (productPropertyMasterDataListId !== null) {
          if (masterDataListIds.indexOf(productPropertyMasterDataListId) === -1) {
            masterDataListIds.push(productPropertyMasterDataListId);
          }
        }
      }
    });

    const observables = [];

    masterDataListIds.forEach(id => {
      observables.push(this.masterDataService.getMasterDataForReports(this.auctionClusterId, id, true).pipe(map((result: MasterData) => result)));
    });

    if (observables.length > 0) {
      forkJoin(
        observables
      ).subscribe((lists: Array<MasterData>) => {
        lists.forEach((result: MasterData) => {
          masterDataLists.push(result);
        });
        this.initReportData(this.auctionClusterId, lists);
      });
    } else {
      this.initReportData(this.auctionClusterId, new Array<MasterData>());
    }
  }

  initReportData(auctionClusterId: number, masterDataLists: Array<MasterData>) {
    this.masterDataDropdowns.length = 0;
    this.reportProperties.filter(x => x.reportDesignId === this.report.reportDesignId)
      .sort((s, z) => s.orderNumber.toString().localeCompare(z.orderNumber.toString()))
      .forEach(reportProperty => {
        const productProperty = this.productProperties.filter(x => x.productPropertyId === reportProperty.productPropertyId)[0];
        const productPropertyId = productProperty.productPropertyId;
        const productPropertyTypeId = productProperty.propertyTypeId;
        const productPropertyMasterDataListId = productProperty.masterDataListId;
        const reportPropertyMasterDataListFieldId = reportProperty.masterDataListFieldId;

        const filter = {
          name: reportProperty.name !== null ? reportProperty.name : productProperty.name,
          sysName: productProperty.sysName,
          propertyTypeId: productPropertyTypeId,
          productPropertyId,
          masterDataListId: productPropertyMasterDataListId,
          masterDataListFieldId: reportPropertyMasterDataListFieldId,
          options: []
        };

        const masterData = masterDataLists.filter(x => x.masterDataListId === productPropertyMasterDataListId
          && x.auctionClusterId === auctionClusterId)[0];

        // We show only date and master data property types.
        if (productPropertyTypeId === ProductPropertyTypeEnum.DATE || productPropertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
          if (productPropertyMasterDataListId !== null) {
            masterData.rows.forEach(row => {
              let rowValueText = '';
              row.values.filter(x => x.masterDataListFieldId === reportPropertyMasterDataListFieldId).forEach(rowValue => {
                if (rowValue.stringValue !== undefined) {
                  rowValueText += rowValue.stringValue;
                }
                if (rowValue.intValue !== undefined) {
                  rowValueText += rowValue.intValue;
                }
              });

              filter.options.push({
                'label': rowValueText,
                'value': row.masterDataListRowId
              });
            });
          }

          this.masterDataDropdowns.push({ productPropertyId: reportProperty.productPropertyId, values: filter.options });

          this.filters.push(filter);

          this.filterBindings[filter.productPropertyId] = this.filterBindings[filter.productPropertyId] !== undefined
            ? this.filterBindings[filter.productPropertyId]
            : null;

          if (productPropertyTypeId === ProductPropertyTypeEnum.MASTER_DATA && reportProperty.defaultMasterDataListRowId !== null) {
            this.filterBindings[filter.productPropertyId] = reportProperty.defaultMasterDataListRowId;
          }

          this.dateFiltersCascadingDefinitions[filter.productPropertyId] =
            this.dateFiltersCascadingDefinitions[filter.productPropertyId] !== null ?
              this.dateFiltersCascadingDefinitions[filter.productPropertyId] : null;

          if (productPropertyTypeId === ProductPropertyTypeEnum.DATE) {
            switch (reportProperty.dateType) {
              case DateRanges.RANGE: {
                this.dateFiltersCascadingDefinitions[filter.productPropertyId] = DateRanges.RANGE.toString();
                this.filterBindings[filter.productPropertyId] = [
                  moment.utc(reportProperty.defaultFromDate),
                  moment.utc(reportProperty.defaultTillDate)
                ];
                break;
              }
              case DateRanges.TODAY: {
                this.dateFiltersCascadingDefinitions[filter.productPropertyId] = DateRanges.TODAY.toString();
                this.filterBindings[filter.productPropertyId] = moment.utc().hours(NOON).format();
                break;
              }
              case DateRanges.YESTERDAY: {
                this.dateFiltersCascadingDefinitions[filter.productPropertyId] = DateRanges.YESTERDAY.toString();
                this.filterBindings[filter.productPropertyId] = moment.utc().subtract(1, 'days').hours(NOON).format();
                break;
              }
              case DateRanges.LAST_WEEK: {
                const dateFrom = moment.utc().subtract(1, 'weeks').hours(NOON);
                const dateTo = moment.utc().hours(NOON);
                this.dateFiltersCascadingDefinitions[filter.productPropertyId] = DateRanges.LAST_WEEK.toString();
                this.filterBindings[filter.productPropertyId] = [dateFrom, dateTo];
                break;
              }
              default:
                break;
            }
          }
        }
      });

    this.masterDataDropdownsFiltered = JSON.parse(JSON.stringify(this.masterDataDropdowns));
    this.dropdownsData = JSON.parse(JSON.stringify(this.masterDataDropdowns));

    this.parseReportFilterData();
    this.setComboBoxes();
  }

  parseReportFilterData() {
    if (!this.report.data) {
      return;
    }

    const filterData = JSON.parse(this.report.data);

    filterData.forEach(filter => {
      if (filter.productPropertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
        this.filterBindings[filter.productPropertyId] = filter.value;
      } else if (filter.productPropertyTypeId === ProductPropertyTypeEnum.DATE) {
        if (filter.dateRangeType !== null) {
          this.dateFiltersCascadingDefinitions[filter.productPropertyId] = filter.dateRangeType.toString();
          if (filter.dateRangeType === DateRanges.RANGE) {
            this.filterBindings[filter.productPropertyId] = [
              moment.utc(filter.value.split('|')[0]),
              moment.utc(filter.value.split('|')[1]),
            ];
          }
        } else {
          this.filterBindings[filter.productPropertyId] = moment.utc(filter.value);
        }
      }
    });
  }

  getFilterForServer() {
    const filterForServer: Array<ReportFilterModel> = [];


    for (const prop of Object.keys(this.filterBindings)) {
      let filterValue = null;
      let filterType = null;

      const filter = this.filters.filter(x => x.productPropertyId === +prop)[0];

      filterType = filter.propertyTypeId;

      let dateFilter = null;

      if (filter.propertyTypeId === ProductPropertyTypeEnum.DATE) {
        dateFilter = this.dateFiltersCascadingDefinitions[prop] ? +this.dateFiltersCascadingDefinitions[prop] : null;
        if (dateFilter === DateRanges.TODAY) {
          filterValue = moment.utc().hours(NOON).format();
        }
        if (dateFilter === DateRanges.YESTERDAY) {
          filterValue = moment.utc().subtract(1, 'days').hours(NOON).format();
        }
        if (dateFilter === DateRanges.LAST_WEEK) {
          const dateFrom = moment.utc().subtract(1, 'weeks').hours(NOON);
          const dateTo = moment.utc().hours(NOON);
          filterValue = dateFrom.format() + '|' + dateTo.format();
        }
        if (dateFilter === DateRanges.RANGE) {
          const dateFrom = this.filterBindings[prop][0];
          const dateTo = this.filterBindings[prop][1];
          filterValue = moment(dateFrom).hours(NOON).format() + '|' + moment(dateTo).hours(NOON).format();
        }
      } else {
        filterValue = this.filterBindings[prop];
      }

      filterForServer.push({
        productPropertyTypeId: +filterType,
        productPropertyId: +prop,
        dateRangeType: dateFilter,
        key: filter.sysName,
        value: filterValue
      });
    }

    return filterForServer;
  }

  filterReport() {
    const filterForServer = this.getFilterForServer();

    const reportRequestModel = {
      LanguageCode: this.translateService.currentLang,
      TimeZoneOffset: new Date().getTimezoneOffset(),
      UserId: this.authenticationService.currentUserId,
      Filters: filterForServer
    };
    this.genericFilters = btoa(JSON.stringify(reportRequestModel));
  }

  openSaveAsNewUserReportTemplateDialog() {
    this.detailsForm.reset();
    this.isSaveProfileDialogOpened = true;
  }

  closeSaveAsNewUserReportTemplateDialog() {
    this.isSaveProfileDialogOpened = false;
  }

  save() {
    this.report.data = JSON.stringify(this.getFilterForServer());
    this.spinner.show();
    this.reportService.edit(this.buyerId, this.report).subscribe(report => {
      this.spinner.hide();
    },
      error => {
        this.spinner.hide();
        this.isSaveProfileDialogOpened = false;
        this.errorService.show(error);
      });
  }

  saveNewReport() {
    const newReport: Report = JSON.parse(JSON.stringify(this.report));
    newReport.data = JSON.stringify(this.getFilterForServer());
    newReport.systemDefault = false;
    newReport.description = this.newReportName;

    this.spinner.show();
    this.reportService.save(this.buyerId, newReport).subscribe(report => {
      this.spinner.hide();
      this.isSaveProfileDialogOpened = false;
      this.getReportTemplates();
    },
      error => {
        this.spinner.hide();
        this.isSaveProfileDialogOpened = false;
        this.errorService.show(error);
      });
  }

  setLotPropertyValue(productPropertyId: number, value: any) {
    let isSame = false;
    if (this.filterBindings[productPropertyId] === value) {
      isSame = true;
    }
    this.filterBindings[productPropertyId] = value;

    return isSame;
  }

  clearLotPropertyValue(productPropertyId: number, e: any) {
    if (e.value === null) {
      this.filterBindings[productPropertyId] = null;
    }
  }

  setComboBoxes() {
    this.comboBoxes.length = 0;
    this.filters.forEach((f, i) => {
      this.comboBoxes[i] = this.getDropdownValues(f);
    });
  }

  getDropdownValues(filter: any) {
    const masterDataDropdown = this.dropdownsData.find(dd => dd.productPropertyId === filter.productPropertyId);
    if (masterDataDropdown) {
      const values = masterDataDropdown.values;

      const currentValue = values.find(v => v.value === this.filterBindings[filter.productPropertyId]);
      if (!currentValue) {
        const md = this.masterDataDropdowns.find(dd => dd.productPropertyId === filter.productPropertyId);
        if (md) {
          const row = md.values.find(v => v.value === filter.masterDataListFieldId);
          if (row) {
            values.push(row);
          }
        }
      }
      values.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }

        return 0;
      });

      if (values.length === 1) {
        const isSame = this.setLotPropertyValue(filter.productPropertyId, values[0].value);
        if (!isSame) {
          this.onPropertyChanged(filter);
        }
      }

      return values;
    }
  }

  getPropIdsForMasterDataFields(lookupTableFields: Array<LookupTableField>) {
    const ids = [];
    lookupTableFields.forEach(ltf => {
      if (ltf.isResult) {
        this.productProperties.forEach(pp => {
          if (pp.masterDataListId === ltf.masterDataListId) {
            ids.push(pp.productPropertyId);
          }
        });
      }
    });

    return ids;
  }

  getLookupDependentPropIds(productPropertyId: number) {
    let dependentPropIds = [];
    const productProperty = this.productProperties.find(f => f.productPropertyId === productPropertyId);
    if (!productProperty) {
      return dependentPropIds;
    }
    const productPropertyMasterData = productProperty.masterDataListId;
    if (!productPropertyMasterData) {
      return dependentPropIds;
    }
    for (let i = 0; i < this.lookupTables.length; i += 1) {
      if (this.lookupTables[i].lookupTableType === 1) {
        continue;
      }
      for (let j = 0; j < this.lookupTables[i].lookupTableFields.length; j += 1) {
        if (!this.lookupTables[i].lookupTableFields[j].isResult
          && this.lookupTables[i].lookupTableFields[j].masterDataListId === productPropertyMasterData) {
          const propIds = this.getPropIdsForMasterDataFields(this.lookupTables[i].lookupTableFields);
          dependentPropIds = [...dependentPropIds, ...propIds];
        }
      }
    }

    return dependentPropIds;
  }

  isProductWithLookupTable(productPropertyId: number) {
    for (let i = 0; i < this.productProperties.length; i += 1) {
      if (this.productProperties[i].productPropertyId === productPropertyId
        && this.productProperties[i].lookupTableId) {
        return this.productProperties[i].lookupTableId;
      }
    }

    return -1;
  }

  onPropertyChanged(filter: any) {
    const applyLookup = (lookup, i) => {
      if (lookup) {
        if (lookup.lookupTableType === 0 && lookup.lookupTableRows.length > 0) {
          const newValues = [];
          lookup.lookupTableRows.forEach(row => {
            row.lookupTableValues.forEach(value => {
              this.masterDataDropdowns[i].values.forEach(mdValue => {
                if (mdValue.value === value.masterDataListRowId && newValues.indexOf(mdValue) === -1) {
                  newValues.push(mdValue);
                }
              });
            });
          });
          if (newValues.length > 0) {
            this.dropdownsData.push({ productPropertyId: this.masterDataDropdowns[i].productPropertyId, values: newValues });
            this.masterDataDropdownsFiltered[i] = {
              productPropertyId: this.masterDataDropdowns[i].productPropertyId, values: newValues, filtered: true
            };
          }
          const defaultRow = lookup.lookupTableRows.find(r => r.isDefault);

          if (defaultRow) {
            defaultRow.lookupTableValues.forEach(ltv => {
              if (newValues.find(nv => nv.value === ltv.masterDataListRowId)) {
                this.setLotPropertyValue(this.masterDataDropdowns[i].productPropertyId, ltv.masterDataListRowId);
              }
            })
          }
        } else {
          if (this.masterDataDropdownsFiltered[i].filtered === true && this.filterBindings[filter.productPropertyId]) {
            this.dropdownsData.push(this.masterDataDropdownsFiltered[i]);
          } else {
            this.dropdownsData.push(this.masterDataDropdowns[i]);
          }
        }
      } else {
        if (this.masterDataDropdownsFiltered[i].filtered === true && this.filterBindings[filter.productPropertyId]) {
          this.dropdownsData.push(this.masterDataDropdownsFiltered[i]);
        } else {
          this.dropdownsData.push(this.masterDataDropdowns[i]);
        }
      }
      this.setComboBoxes();
    };

    const productPropertyIds = this.getLookupDependentPropIds(filter.productPropertyId);

    if (productPropertyIds.length === 0) {
      return;
    }

    // clear values first
    this.dropdownsData.length = 0;
    for (let i = 0; i < this.masterDataDropdowns.length; i += 1) {
      let checkThisProp = this.masterDataDropdowns[i].productPropertyId !== filter.productPropertyId;
      if (productPropertyIds.length > 0 && productPropertyIds.indexOf(this.masterDataDropdowns[i].productPropertyId) === -1) {
        checkThisProp = false;
      }
      const lookupTableId = this.isProductWithLookupTable(this.masterDataDropdowns[i].productPropertyId);
      if (checkThisProp && lookupTableId !== -1) {
        const lot = this.createLotFromBindings();
        this.lookupTableService.getLookupTableForProductPropertyForReports(null, this.masterDataDropdowns[i].productPropertyId, lot)
          .subscribe(lookup => {
            applyLookup(lookup, i);
          }, error => {
            if (this.masterDataDropdownsFiltered[i].filtered === true && this.filterBindings[filter.productPropertyId]) {
              this.dropdownsData.push(this.masterDataDropdownsFiltered[i]);
            } else {
              this.dropdownsData.push(this.masterDataDropdowns[i]);
            }
            this.setComboBoxes();
          });
      } else {
        if (this.masterDataDropdownsFiltered[i].filtered === true && this.filterBindings[filter.productPropertyId]) {
          this.dropdownsData.push(this.masterDataDropdownsFiltered[i]);
        } else {
          this.dropdownsData.push(this.masterDataDropdowns[i]);
        }
        if (productPropertyIds.length > 0) {
          this.setComboBoxes();
        }
      }
    }
  }

  createLotFromBindings() {
    const lot = new Lot();

    this.filters.forEach(f => {
      if (f.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
        const lotProp = new LotProperty();
        lotProp.productPropertyId = f.productPropertyId;
        lotProp.masterDataListRowId = this.filterBindings[f.productPropertyId];
        lot.lotProperties.push(lotProp);
      }
    });

    return lot;
  }

  showLoadReportProfile() {
    this.isLoadProfileDialogOpened = true;
  }

  closeLoadReportProfile() {
    this.isLoadProfileDialogOpened = false;
  }

  reportTemplateChange() {
    this.isLoadProfileDialogOpened = false;

    let report = this.allReports.find(_ => _.reportId === this.reportId);
    if (report) {
      this.applyReportFilters(report);
    }
  }

  translateSelectBoxes = (item: any) => {
    if (item) {
      const label = this.getTranslation(item.label);
      return label;
    }
  }

  prepareData(index: number) {
    return this.comboBoxes[index];
  }

  prepareValue(index: number) {
    return this.filterBindings[index];
  }

  handleDate1ValueChange(index: number, e: any) {
    if (e.value != null) {
      this.filterBindings[index] = [];
      this.filterBindings[index].push(moment(e.value));
    }
  }

  handleDate2ValueChange(index: number, e: any) {
    if (e.value != null) {
      this.filterBindings[index].push(moment(e.value));
    }
  }

  dateValue1(index: number) {
    if (Array.isArray(this.filterBindings[index]) && this.filterBindings[index].length > 0) {
      return moment(this.filterBindings[index][0]).toDate();
    } else {
      return null;
    }
  }

  dateValue2(index: number) {
    if (Array.isArray(this.filterBindings[index]) && this.filterBindings[index].length > 1) {
      return moment(this.filterBindings[index][1]).toDate();
    } else {
      return null;
    }
  }

  getMin(index: number) {
    if (Array.isArray(this.filterBindings[index]) && this.filterBindings[index].length > 0) {
      return moment(this.filterBindings[index][0]).toDate();
    }
  }

  isDisabled(index: number) {
    if (Array.isArray(this.filterBindings[index])) {
      if (this.filterBindings[index].length === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }


  clearDatePeriod(index: number) {
    if (this.dateFiltersCascadingDefinitions[index] !== 3) {
      this.filterBindings[index] = [];
    }
  }

  setValue(index: number, e: any) {
    this.dateFiltersCascadingDefinitions[index] = e.value;
  }

  getDateValue(index: number) {
    return Number(this.dateFiltersCascadingDefinitions[index]);
  }

  isDisabledButton() {
    if (this.newReportName !== '') {
      return false;
    } else {
      return true;
    }
  }

  isDisabledLoadButton() {
    if (this.reportId !== null) {
      return false;
    } else {
      return true;
    }
  }
}
