import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

// components
import { ContentComponent } from '../../shared/content/content.component';

// models
import { AccessLevel, AdditionalMenuItem } from '../../shared/models/additional-menu-item';

// services
import { LanguageService } from '../../shared/services/language.service';

import { AuctionClusterService } from '../shared/services/auction-cluster.service';


@Component({
  selector: 'supplier-content.component',
  templateUrl: './supplier-content.component.html',
  styleUrls: ['./supplier-content.component.scss']
})
export class SupplierContentComponent extends ContentComponent implements OnInit, OnDestroy {

  myRoute: any;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  menuItems: Array<AdditionalMenuItem> = [];
  subMenuItems: Array<AdditionalMenuItem> = [];
  auctionClusterId: Number;

  constructor(
    injector: Injector,
    route: ActivatedRoute,
    private auctionClusterService: AuctionClusterService,
    languageService: LanguageService,
    private titleService: Title,
  ) {
    super(injector);

    this.myRoute = route;

    this._subscription = this.languageService.direction.subscribe(res => {
      this.rtlEnabled = res;
    });
  }

  ngOnInit() {
    this.sidebarFirstLogo = null; 
    this.sidebarSecondLogo = null; 
    
    this.auctionClusterService.getAuctionClusters(this.id).subscribe(acs => {
      if (acs && acs.length > 0) {
        this.auctionClusterId = acs[0].auctionClusterId;
        this.manageLocaleStorageLogoImages(acs[0].image, acs[0].secondLogo);
        if (acs[0].pageTitle) {
          this.titleService.setTitle(acs[0].pageTitle);
        }
        if (acs[0].favicon){
          const favicon = document.getElementById('faviconchangable') as HTMLLinkElement;
          if (favicon) {
            favicon.href = acs[0].favicon;
          }
        }
      } else {
          this.removeLocalStorageLogoImages();
      }

      const firstLogo = localStorage.getItem('AUCXIS_SIDEBAR_LOGO');
      const secondLogo = localStorage.getItem('AUCXIS_SIDEBAR_SECOND_LOGO');

      if (firstLogo != null) {
        this.sidebarFirstLogo = firstLogo;
      }

      if (secondLogo != null) {
        this.sidebarSecondLogo = secondLogo;
      }
    });

    super.ngOnInit();

    this.additionalMenuItemsService.getPublicMenuItems().subscribe(result => {

            if (result !== undefined) {
                result.forEach(item => {
                    if (item.accessLevels.length > 0) {
                        item.accessLevels.forEach(_ => {
                            if (_ === AccessLevel.SUPPLIER) {
                                if (item.submenuId !== null) {
                                    this.subMenuItems.push(item);
                                } else this.menuItems.push(item);
                            }
                        })
                    }
                })

                this.menuItems.sort((a, b) => {
                    if (a.orderNumber < b.orderNumber) {
                        return -1;
                    }
                    if (a.orderNumber > b.orderNumber) {
                        return 1;
                    }
                    return 0;
                })

                this.subMenuItems.sort((a, b) => {
                    if (a.orderNumber < b.orderNumber) {
                        return -1;
                    }
                    if (a.orderNumber > b.orderNumber) {
                        return 1;
                    }
                    return 0;
                })
            }
        },
            error => {
                this.errorService.show(error);
            });
    }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
