<div dir="{{rtlEnabled ? 'rtl' : 'ltr'}}" class="main-container content-component" [class.test]="baseUrl && (baseUrl.includes('test') || baseUrl.includes('qa'))">
  <div class="content-container">
    <div class="content-area">
      <header-component></header-component>
      <div class="contents">
        <alert-component></alert-component>
        <router-outlet></router-outlet>
      </div>
    </div>
    <nav class="sidenav" [ngClass]="{'collapsed': !expanded && !pin}">
      <section class="sidenav-content" *ngIf="expanded || pin">
        <div class="collapsed-btn" (click)="navCollapse()">
          <clr-icon shape="arrow left" size="40" style="padding-left: 16px;padding-bottom: 16px"></clr-icon>
        </div>

        <a [routerLink]="['/platform/users']" class="logo-wrapper" (click)="onRouterLinkClick()">
          <img *ngIf="!(sidebarFirstLogo || sidebarSecondLogo)" src="assets/images/Aucxis_w.png" class="aucxis-logo" />
          <img *ngIf="!(sidebarFirstLogo || sidebarSecondLogo)" src="assets/images/efice.png" class="efice-logo" style="display: none;" />
          <img *ngIf="sidebarFirstLogo" [src]="sidebarFirstLogo"/>
          <img *ngIf="sidebarSecondLogo" [src]="sidebarSecondLogo"/>
        </a>

        <!--<div class="au-sidebar-menu-item nav-item">
          <a routerLinkActive="active" [routerLink]="['/platform/home']" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
            <p class="au-sidebar-menu-item-text">{{ 'PLATFORM.OVERVIEW' | translate }}</p>
          </a>
        </div>-->

        <collapsable-section *ngIf="tokenService.permissionMet('PlatformPermissions.1')||tokenService.permissionMet('PlatformPermissions.8')" sectionId="platform-users" [caption]="'SHARED.USERS_ROLES_MANAGEMENT' | translate" (clicked)="onRouterLinkClick('platform-users')">
          <ul class="nav-list">
            <li *ngIf="tokenService.permissionMet('PlatformPermissions.1')" class="au-sidebar-menu-item nav-item">
              <a [routerLink]="['/platform/users']" routerLinkActive="active" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('platform-users')">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-user"></i>
                <p class="au-sidebar-menu-item-text">{{ 'SHARED.USERS' | translate }}</p>
              </a>
            </li>
            <li *ngIf="tokenService.permissionMet('PlatformPermissions.8')" class="au-sidebar-menu-item nav-item">
              <a [routerLink]="['/platform/roles']" routerLinkActive="active" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('platform-users')">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-card"></i>
                <p class="au-sidebar-menu-item-text">{{ 'ROLES.TITLE' | translate }}</p>
              </a>
            </li>
          </ul>
        </collapsable-section>

        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('PlatformPermissions.3')">
          <a routerLinkActive="active"
             [routerLink]="['/platform/clusters']" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-mediumiconslayout"></i>
            <p class="au-sidebar-menu-item-text">{{ 'SHARED.AUCTION_CLUSTER_MANAGEMENT' | translate }}</p>
          </a>
        </div>

        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('PlatformPermissions.4')">
          <a routerLinkActive="active"
             [routerLink]="['/platform/buyers']" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-money"></i>
            <p class="au-sidebar-menu-item-text">{{ 'SHARED.BUYER_MANAGEMENT' | translate }}</p>
          </a>
        </div>

        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('PlatformPermissions.5')">
          <a routerLinkActive="active"
             [routerLink]="['/platform/suppliers']" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-cart"></i>
            <p class="au-sidebar-menu-item-text">{{ 'SHARED.SUPPLIER_MANAGEMENT' | translate }}</p>
          </a>
        </div>

        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('PlatformPermissions.6')">
          <a routerLinkActive="active" [routerLink]="['/platform/reports']" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-checklist"></i>
            <p class="au-sidebar-menu-item-text">{{ 'SHARED.REPORTING' | translate }}</p>
          </a>
        </div>

        <div class="au-sidebar-menu-item nav-item" *ngIf="tokenService.permissionMet('PlatformPermissions.7')">
          <a routerLinkActive="active" [routerLink]="['/platform/additional-menu-items']" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-menu"></i>
            <p class="au-sidebar-menu-item-text">{{ 'PLATFORM.ADDITIONAL_MENU_ITEMS' | translate }}</p>
          </a>
        </div>

        <div class="au-sidebar-menu-item nav-item">
          <a routerLinkActive="active" [routerLink]="['/platform/supplyforecast/']" class="nav-link au-sidebar-menu-item-link public-supply-forecast-urk" (click)="onRouterLinkClick()">
            <i class="au-sidebar-menu-item-icon dx-icon dx-icon-cart"></i>
            <p class="au-sidebar-menu-item-text">{{ 'CONTENT.SUPPLY_FORECAST' | translate }}</p>
          </a>
        </div>

        <collapsable-section *ngIf="tokenService.permissionMet('PlatformPermissions.9')" sectionId="management" [caption]="'PLATFORM.PLATFORM_MANAGEMENT' | translate" (clicked)="onRouterLinkClick('management')">
          <ul class="nav-list">
            <li class="au-sidebar-menu-item">
              <a [routerLink]="['/platform/configurationparameter']" routerLinkActive="active" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('management')">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-preferences"></i>
                <p class="au-sidebar-menu-item-text">{{ 'PLATFORM.CONFIGURATION_PARAMETERS' | translate }}</p>
              </a>
            </li>
            <li class="au-sidebar-menu-item">
              <a routerLinkActive="active" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('management')">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-product"></i>
                <p class="au-sidebar-menu-item-text">{{ 'PLATFORM.PRODUCT_DEFINITION' | translate }}</p>
              </a>
            </li>
            <li class="au-sidebar-menu-item">
              <a routerLinkActive="active" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('management')">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-key"></i>
                <p class="au-sidebar-menu-item-text">{{ 'PLATFORM.LICENSING' | translate }}</p>
              </a>
            </li>
            <li class="au-sidebar-menu-item">
              <a [routerLink]="['/platform/importauctioncluster']" routerLinkActive="active" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('management')">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-import"></i>
                <p class="au-sidebar-menu-item-text">{{ 'PLATFORM.IMPORT_AUCTIONCLUSTER' | translate }}</p>
              </a>
            </li>
            <li class="au-sidebar-menu-item">
              <a [routerLink]="['/platform/externalplatforms']" routerLinkActive="active" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('management')">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-showpanel"></i>
                <p class="au-sidebar-menu-item-text">{{ 'PLATFORM.EXTERNAL_PLATFORMS' | translate }}</p>
              </a>
            </li>
          </ul>
        </collapsable-section>

        <collapsable-section *ngIf="tokenService.permissionMet('PlatformPermissions.9')" sectionId="styling" [caption]="'PLATFORM.STYLING' | translate" (clicked)="onRouterLinkClick('styling')">
          <ul class="nav-list">
            <li class="au-sidebar-menu-item">
              <a [routerLink]="['/platform/productpropertystylings']" routerLinkActive="active" class="nav-link au-sidebar-menu-item-link" (click)="onRouterLinkClick('styling')">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-product"></i>
                <p class="au-sidebar-menu-item-text">{{ 'SHARED.PRODUCT_PROPERTY' | translate }}</p>
              </a>
            </li>
          </ul>
        </collapsable-section>

        <ng-container *ngFor="let item of menuItems">
          <collapsable-section *ngIf="item.menuType === 3" [caption]="getTranslation(item.menuItemName)" sectionId="item.menuItemName" (clicked)="onRouterLinkClick('item.menuItemName')">
            <ul class="nav-list">
              <ng-container *ngFor="let subItem of subMenuItems">
                <ng-container *ngIf="item.externalAppId === subItem.submenuId">
                  <li class="au-sidebar-menu-item">
                    <a *ngIf="!subItem.openInNewTab"
                       [routerLink]="['/platform/menu-item/' + subItem.externalAppId]"
                       routerLinkActive="active"
                       class="nav-link au-sidebar-menu-item-link">
                      <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
                      <p class="au-sidebar-menu-item-text">{{ getTranslation(subItem.menuItemName) }}</p>
                    </a>
                  </li>
                  <li class="au-sidebar-menu-item">
                    <a *ngIf="subItem.openInNewTab"
                       [href]="subItem.applicationUrl"
                       [target]="'_blank'"
                       routerLinkActive="active"
                       class="nav-link au-sidebar-menu-item-link">
                      <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
                      <p class="au-sidebar-menu-item-text">{{ getTranslation(subItem.menuItemName) }}</p>
                    </a>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </collapsable-section>

          <ng-container *ngIf="item.menuType === 1">
            <div class="au-sidebar-menu-item">
              <a *ngIf="!item.openInNewTab"
                 [routerLink]="['/platform/menu-item/' + item.externalAppId]"
                 routerLinkActive="active"
                 class="nav-link au-sidebar-menu-item-link">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
                <p class="au-sidebar-menu-item-text">{{ getTranslation(item.menuItemName) }}</p>
              </a>

              <a *ngIf="item.openInNewTab"
                 [href]="item.applicationUrl"
                 [target]="'_blank'"
                 routerLinkActive="active"
                 class="nav-link au-sidebar-menu-item-link">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
                <p class="au-sidebar-menu-item-text">{{ getTranslation(item.menuItemName) }}</p>
              </a>
            </div>
          </ng-container>

          <ng-container *ngIf="item.menuType === 2">
            <div class="au-sidebar-menu-item">
              <a [routerLink]="['/platform/menu-item/' + item.externalAppId]"
                 routerLinkActive="active"
                 class="nav-link au-sidebar-menu-item-link">
                <i class="au-sidebar-menu-item-icon dx-icon dx-icon-more"></i>
                <p class="au-sidebar-menu-item-text">{{ getTranslation(item.menuItemName) }}</p>
              </a>
            </div>
          </ng-container>

        </ng-container>
      </section>

      <div class="au-sidebar-bottom-brand-area">
        <a *ngIf="expanded || pin" [href]="kosmosURL" target="_blank" class="bottom-logo-wrapper">
          <img src="assets/images/kosmos.png" />
        </a>
        <version-component></version-component>
      </div>

      <section class="sidenav-content" *ngIf="!expanded && !pin">
        <a [routerLink]="['/core/home']" class="small-logo-wrapper">
          <!--<img src="assets/images/europool_logo_small.png" />-->
        </a>
        <div class="expand-btn" (click)="navExpand()">
          <clr-icon shape="arrow right" size="32"></clr-icon>
        </div>
        <a [href]="kosmosURL" target="_blank" class="bottom-logo-wrapper">
          <img src="assets/images/kosmos_vert.png" />
        </a>
      </section>
    </nav>
  </div>
</div>
<spinner-component></spinner-component>
