import { Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import DataGrid from 'devextreme/ui/data_grid';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { Subscription } from 'rxjs';

// components
import { ConfirmationComponent } from '../../shared/components/confirmation/confirmation.component';
import { ItemListComponent } from '../../shared/components/item-list/item-list.component';

// models
import { AuctionCluster } from '../../shared/models/auction-cluster';
import { ApplicationSettings } from '../../shared/models/application-settings';
import { Catalog, CatalogsRequest } from '../../shared/models/catalog';
import { ConfirmationStatus, TransactionMonitorLot, TransactionMonitorLotColumn, TransactionMonitorLotRow } from '../../shared/models/transaction-monitor-lot';
import { DateTimeTypeEnum } from '../../shared/enum/dateTimeTypeEnum';
import { ExportConstants } from '../../shared/constants/export.constants';
import { ProductPropertyTypeEnum } from '../../shared/enum/productPropertyTypeEnum';
import { UrlTypeEnum } from '../../shared/enum/urlTypeEnum';

// services
import { TranslateService } from '@ngx-translate/core';
import { ServiceFactory } from '../../shared/factory/service-factory';
import { TitleService } from '../../shared/services/title.service';
import { WebApiService } from '../../shared/services/web-api.service';

import { AuctionClusterService } from '../shared/services/auction-cluster.service';
import { CatalogService } from '../shared/services/catalog.service';
import { BuyerTransactionMonitorService } from '../shared/services/buyer-transaction-monitor.service';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'buyer-transaction-monitor-component',
  templateUrl: 'buyer-transaction-monitor.component.html',
  styleUrls: ['buyer-transaction-monitor.component.scss']
})
export class BuyerTransactionMonitorComponent extends ItemListComponent<TransactionMonitorLotRow> implements OnInit, OnDestroy {
  auctionClusterService: AuctionClusterService;
  catalogService: CatalogService;
  buyerId: number = null;
  auctionClusterId: number = null;
  auctionClusters: Array<AuctionCluster> = [];
  currentLot: TransactionMonitorLot = null;
  catalogId: number = null;
  auctionId: number = null;
  catalogs: Array<Catalog> = [];
  date: Date = new Date();
  columns: Array<any> = [];
  data: Array<any> = new Array<any>();
  objectKeys: Array<string> = new Array<string>();
  items = [];
  filterPrebids = false;
  filterMarked = false;
  reportId = 0;
  currentTransactionId: number;
  currentPrice: number;

  exportConstants: ExportConstants = new ExportConstants();
  exportUrl: SafeUrl;
  exportFilename: string;

  @ViewChild('exportLink') exportLink: ElementRef;
  @ViewChild('acceptTransactionDialog') acceptTransactionDialog: ConfirmationComponent;
  @ViewChild('rejectTransactionDialog') rejectTransactionDialog: ConfirmationComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  dateFilterItems = [];
  dataGridInstance: DataGrid;
  gridColumns = [];
  allMode: string;
  checkBoxesMode: string;
  selectedRows: Array<number> = [];
  gridItems = [];
  lastTimeSentNotification = '';

  constructor(
    protected appSettings: ApplicationSettings,
    protected route: ActivatedRoute,
    protected webApiService: WebApiService,
    protected injector: Injector,
    private transactionMonitorService: BuyerTransactionMonitorService,
    private sanitizer: DomSanitizer,
    private titleService: TitleService,
    private translateService: TranslateService,
  ) {
    super(injector, TransactionMonitorLotRow);
    this.getServices(route, appSettings, webApiService);
    this.title.set('TRANSACTION_MONITOR.TITLE');
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  async ngOnInit() {
    this.setTranslations('TRANSACTION_MONITOR');

    this.spinner.show();
    var result = await this.auctionClusterService.getAuctionClustersPerLevelWithoutImage().toPromise();
    this.auctionClusters = result;
    if (this.auctionClusters.length !== 0) {
      this.auctionClusterId = this.auctionClusters[0].auctionClusterId;
    }

    this.buyerId = this.id;
    this.filterCatalogs();

    this.spinner.hide();
  }

  ngOnDestroy() {
    // Make sure to call ngOnDestroy method of inherited class to do the necessary
    // unsubscriptions and other cleanups which might cause app to hang if not propery handled
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getServices(route, appSettings, webApiService) {
    this.auctionClusterService = ServiceFactory.getAuctionClusterService(document.location.hash, route, appSettings, webApiService) as AuctionClusterService;
    this.catalogService = ServiceFactory.getCatalogService(route, appSettings, webApiService) as CatalogService;
  }

  getData() {
    if (!this.buyerId || !this.catalogId) {
      return;
    }

    // Fetch lots without columns refresh
    this.fetchLots(false);
  }

  fetchLots(refreshColumns: boolean) {
    // Reset any existing filtering if column refresh is requested
    if (refreshColumns) {
      this.columns = [];
    }

    this.data = [];
    this.items = [];
    const dateFormatted = moment(this.date).local().format('YYYY-MM-DD');
    this.spinner.show();

    this.transactionMonitorService.getLots(this.auctionClusterId, this.buyerId, this.catalogId, dateFormatted)
      .subscribe(result => {
        this.currentLot = result;
        this.items = result.rows;
        if (refreshColumns) {
          // Parse columns only if explicitly requested
          this.parseColumns(result.columnTitles);
        }
        this.parseObject(result.rows, result.columnTitles);
        this.generateTable(result);
        this.spinner.hide();
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
  }

  exportToCsv() {
    let csvString = '';
    let delimiter = ';';
    const currentLanguageCode = this.translateService.currentLang;

    this.language.getLanguages().subscribe(l => {
      const currentLanguge = l.filter(x => x.code === currentLanguageCode)[0]
      if (currentLanguge !== undefined) {
        delimiter = currentLanguge.csvDelimiter;
      }
    });

    this.columns.forEach(c => {
      csvString += `"${c.name}"${delimiter}`;
    });

    csvString += `
    `;

    this.data.forEach(d => {
      this.objectKeys.forEach(key => {
        // Remove line breaks for export
        let value = this.removeLineBreaks(d[key]);
        csvString += `"${value}"${delimiter}`;
      });
      csvString += `
      `;
    });

    const blob = new Blob([this.exportConstants.BLOB_HEADER + csvString], { type: this.exportConstants.BLOB_TYPE });
    this.exportUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(blob));
    const title = this.titleService.text.getValue();
    this.exportFilename = `${title}.${this.exportConstants.FILE_EXTENSION_CSV}`;

    window.setTimeout(() => { // anchor requires time interval to refresh its state
      this.exportLink.nativeElement.click();
      csvString = '';
    });
  }

  clearAll() {
    this.data.forEach(d => {
      if (d.__item__) {
        d.__item__.selected = false;
      }
    });
  }

  private parseObject(rows: Array<TransactionMonitorLotRow>, columns: Array<TransactionMonitorLotColumn>) {
    this.data = [];

    // Ignore parsing if there are no columns defined
    if (this.columns.length === 0) {
      return;
    }

    rows.forEach(row => {
      if (row.groupedIds) {
        if (row.groupedIds.length == 0) {
          row.values.unshift("");
        }
        else {
          let ids = "";
          row.groupedIds.forEach((id, i) => {
            ids += id;
            if (i != (row.groupedIds.length - 1)) {
              ids += ", ";
            }
          });
          row.values.unshift(ids);
        }

      }
      const obj = new Object();
      this.columns.forEach((column, i) => {
        if (column.propertyTypeId === ProductPropertyTypeEnum.DATE) {
          if (column.propertyTypeFormatId == DateTimeTypeEnum.SHORT_DATE) {
            let date = new Date(row.values[i]);
            date.setHours(12);
            date.setMinutes(0);
            obj['key' + i] = (date).toISOString();
          }
          else {
            obj['key' + i] = this.addLineBreaks(row.values[i], column.propertyTypeId, column.propertyTypeFormatId);
          }
        }
        else if (column.propertyTypeId === ProductPropertyTypeEnum.TEXT
          || column.propertyTypeId === ProductPropertyTypeEnum.BOOLEAN
          || column.propertyTypeId === ProductPropertyTypeEnum.IMAGE
          || column.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA
          || isNaN(Number(row.values[i]))) {
          // Add line breaks for string value
          let objValue = this.getTranslation(row.values[i]);
          objValue = this.addLineBreaks(objValue, column.propertyTypeId);
          obj['key' + i] = objValue;
        } else {
          obj['key' + i] = row.values[i]; //this.format(Number(row.values[i]), column.propertyTypeId);
        }
      });
      obj['__item__'] = row;

      this.data.push(obj);
    });

    // Reset default page size
    this.setPageSize(this.pageSizeValue);
  }

  private parseColumns(columns: Array<TransactionMonitorLotColumn>) {
    const _columns = [];

    // Additional columns for displaying transaction lot ids
    _columns.push({
      name: this.getTranslation(this.translations.IDS),
      propertyTypeId: ProductPropertyTypeEnum.TEXT,
      propertyTypeFormatId: null
    });

    columns.forEach(column => {
      _columns.push({
        name: this.getTranslation(column.name),
        propertyTypeId: column.propertyTypeId,
        propertyTypeFormatId: column.propertyTypeFormatId
      });
    });

    const _objectKeys = [];
    _columns.forEach((column, i) => {
      _objectKeys.push('key' + i);
    });

    this.objectKeys = _objectKeys;
    this.columns = _columns;
    this.createDataSchema();
  }

  filterLots() {
    // Fetch data and force columns refresh
    this.fetchLots(true);
  }

  filterCatalogs() {
    if (!this.buyerId) {
      this.catalogs = [];
      this.catalogId = null;
      return;
    }

    var catalogsRequest = new CatalogsRequest();
    catalogsRequest.salesDate = this.date;
    catalogsRequest.isTransaction = true;

    this.catalogService.getAllCatalogsWithSupplyOrTransactions(catalogsRequest)
      .subscribe(result => {
        this.catalogs = result;
        if (this.catalogs.length !== 0) {
          this.catalogId = this.catalogs[0].catalogId;
          this.auctionId = this.catalogs[0].auctionId;
        }
      });
  }

  anyRowSelected() {
    return this.data && this.data.length > 0 && this.data.filter(d => d.__item__ && d.__item__.selected === true).length > 0;
  }

  dateChanged(dateTime: Moment) {
    this.date = moment(dateTime).toDate();
    this.filterCatalogs();
  }

  requestAcceptTransaction = (e: any) => {
    this.currentTransactionId = e.row.key.id;
    this.currentPrice = e.row.key.price;
    this.acceptTransactionDialog.opened = true;
  }

  requestRejectTransaction = (e: any) => {
    this.currentTransactionId = e.row.key.id;
    this.currentPrice = e.row.key.price;
    this.rejectTransactionDialog.opened = true;
  }

  acceptTransaction = async () => {
    this.spinner.show();
    try {
      await this.transactionMonitorService.acceptTransaction(this.auctionClusterId, this.buyerId, this.currentTransactionId, this.currentPrice).toPromise();

      this.getData();
    }
    catch (error) {
      if (error.status == 422) {
        this.errorService.show(this.translate.instant('TRANSACTION_MONITOR.PRICECHANGED'));
      }
      else {
        this.errorService.show(error);
      }
    }
    finally {
      this.spinner.hide();
    }
  }

  rejectTransaction = async () => {
    this.spinner.show();
    try {
      await this.transactionMonitorService.rejectTransaction(this.auctionClusterId, this.buyerId, this.currentTransactionId).toPromise();

      this.getData();
    }
    catch (error) {
      this.errorService.show(error);
    }
    finally {
      this.spinner.hide();
    }
  }

  openReport = (e: any) => {
    let url = '/#/auction/reports/' + this.buyerId + '/report/' + this.reportId + '?clockStartGuid=' + e.row.data.clockStartGuid;
    window.open(url);
  }

  initColumn = (e: any) => {
    this.dataGridInstance = e.component;
  }

  generateTable = (rows: any) => {
    this.gridColumns = [];
    const gridCurrentColums = this.dataGridInstance.getVisibleColumns();

    if (!gridCurrentColums.some(col => col.dataField === this.translations.IDS)) {
      this.dataGridInstance.addColumn({
        dataField: this.getTranslation(this.translations.IDS),
        caption: this.translations.IDS,
        visibleIndex: 0,
        allowEditing: false,
        encodeHtml: false,
        formItem: { visible: false },
        editorOptions: {
          showClearButton: true
        }
      });
    }
    this.gridColumns.push(this.translations.IDS);


    rows.columnTitles.forEach((row: any, i: number) => {
      let columnName = this.getTranslation(row.name);
      if (this.gridColumns.includes(columnName)) {
        let j = 0;
        do {
          columnName = this.getTranslation(row.name) + j;
          j++;
        } while (this.gridColumns.includes(columnName));
      }
      this.gridColumns.push(columnName);
      const isColumn = gridCurrentColums.find(c => c.dataField === columnName);
      const isEditable = row.uniqueName == 'PRICE';
      if (!isColumn) {
        this.dataGridInstance.addColumn({
          dataField: columnName,
          name: row.uniqueName,
          caption: this.getTranslation(row.name),
          visibleIndex: i + 1,
          allowEditing: isEditable,
          dataType: this.getDataType(row.propertyTypeId, row.propertyTypeFormatId),
          encodeHtml: false,
          cellTemplate: this.assignDefaultCellTemplate(row.propertyTypeId, row.propertyTypeFormatId),
          formItem: {
            editorOptions: {
              inputAttr: { 'style': `background-color: ${isEditable ? '#FFFFFF' : '#EEEEEE'}` }
            }
          }
        });
      }
    });

    this.gridColumns.push('id');
    if (!gridCurrentColums.some(col => col.dataField === 'id')) {
      this.dataGridInstance.addColumn({
        dataField: 'id',
        caption: 'id',
        visible: false,
        formItem: { visible: false },
        allowEditing: false
      });
    }

    this.gridColumns.push('clockStartGuid');
    if (!gridCurrentColums.some(col => col.dataField === 'clockStartGuid')) {
      this.dataGridInstance.addColumn({
        dataField: 'clockStartGuid',
        caption: 'clockStartGuid',
        formItem: { visible: false },
        visible: false,
        allowEditing: false
      });
    }

    this.gridColumns.push('isTransactionAutoCreated');
    if (!gridCurrentColums.some(col => col.dataField === 'isTransactionAutoCreated')) {
      this.dataGridInstance.addColumn({
        dataField: 'isTransactionAutoCreated',
        caption: 'isTransactionAutoCreated',
        formItem: { visible: false },
        visible: false,
        allowEditing: false
      });
    }

    this.gridColumns.push('canAskForConfirmation');
    if (!gridCurrentColums.some(col => col.dataField === 'canAskForConfirmation')) {
      this.dataGridInstance.addColumn({
        dataField: 'canAskForConfirmation',
        caption: 'canAskForConfirmation',
        formItem: { visible: false },
        visible: false,
        allowEditing: false
      });
    }

    this.gridColumns.push('canEditTransaction');
    if (!gridCurrentColums.some(col => col.dataField === 'canEditTransaction')) {
      this.dataGridInstance.addColumn({
        dataField: 'canEditTransaction',
        caption: 'canEditTransaction',
        formItem: { visible: false },
        visible: false,
        allowEditing: false
      });
    }

    this.gridColumns.push('transactionConfirmationStatus');
    if (!gridCurrentColums.some(col => col.dataField === 'transactionConfirmationStatus')) {
      this.dataGridInstance.addColumn({
        dataField: 'transactionConfirmationStatus',
        caption: 'transactionConfirmationStatus',
        formItem: { visible: false },
        visible: false,
        allowEditing: false
      });
    }

    this.gridColumns.push('price');
    if (!gridCurrentColums.some(col => col.dataField === 'price')) {
      this.dataGridInstance.addColumn({
        dataField: 'price',
        caption: 'price',
        formItem: { visible: false },
        visible: false,
        allowEditing: false
      });
    }

    this.gridColumns.push('isDeleted');
    if (!gridCurrentColums.some(col => col.dataField === 'isDeleted')) {
      this.dataGridInstance.addColumn({
        dataField: 'isDeleted',
        caption: 'isDeleted',
        formItem: { visible: false },
        visible: false,
        allowEditing: false
      });
    }

    this.generateTableData();
  }

  assignDefaultCellTemplate(propertyTypeId: ProductPropertyTypeEnum, propertyTypeFormatId?: UrlTypeEnum) {
    /*if (propertyTypeId === ProductPropertyTypeEnum.URL && propertyTypeFormatId === UrlTypeEnum.IMAGE_URL) {
      return 'imageCellTemplate';
    } else */if (propertyTypeId === ProductPropertyTypeEnum.TEXT) {
      return 'textCellTemplate';
    } else {
      return 'cellTemplate';
    }
  }


  generateTableData() {
    this.gridItems = [];
    this.data.forEach((row, i) => {
      let gridItem = '';
      this.gridColumns.forEach((column, j) => {
        if (column === 'id') {
          gridItem += '"' + column + '" : "' + row.__item__.id + '",';
        } else if (column === 'price') {
          gridItem += '"' + column + '" : "' + row.__item__.price + '",';
        } else if (column === 'clockStartGuid') {
          gridItem += '"' + column + '" : "' + row.__item__.clockStartGuid + '",';
        } else if (column === 'isTransactionAutoCreated') {
          gridItem += '"' + column + '" : "' + row.__item__.isTransactionAutoCreated + '",';
        } else if (column === 'canEditTransaction') {
          gridItem += '"' + column + '" : "' + this.catalogs.find(c => c.catalogId == row.__item__.catalogId).allowEditTransactionPrice + '",';
        } else if (column === 'canAskForConfirmation') {
          gridItem += '"' + column + '" : "' + this.catalogs.find(c => c.catalogId == row.__item__.catalogId).allowMarkForConfirmation + '",';
        } else if (column === 'transactionConfirmationStatus') {
          gridItem += '"' + column + '" : "' + row.__item__.transactionConfirmationStatus + '",';
        } else if (column === 'isDeleted') {
          gridItem += '"' + column + '" : "' + row.__item__.isDeleted + '",';
        } else {
          gridItem += '"' + column + '" : "' + this.getTranslation(row['key' + j]) + '",';
        }
      });
      this.gridItems.push(JSON.parse('{ ' + gridItem.slice(0, -1) + '}'));
    });

    const rows = this.dataGridInstance.getDataSource();
    if (rows === null) {
      if (this.gridItems.length > 0) {
        this.dataGridInstance.option('dataSource', this.gridItems);
      }
    } else {
      this.dataGridInstance.option('dataSource', this.gridItems);
    }
  }

  isTransactionAutoCreated = (e: any) => {
    return e.row.data.isTransactionAutoCreated === 'true';
  }

  canAskForConfirmation = (e: any) => {
    return e.row.data.canAskForConfirmation === 'true' && e.row.data.transactionConfirmationStatus != ConfirmationStatus.WaitingForConfirmation && e.row.data.isDeleted === 'false';
  }

  buttonsVisible = () => {
    if (this.dataGridInstance != undefined) {
      var rows = this.dataGridInstance.getVisibleRows();
      return rows.some(r => r.data.isDeleted == 'false' && r.data.canAskForConfirmation == 'true' && r.data.transactionConfirmationStatus == ConfirmationStatus.WaitingForConfirmation);
    }
  }

  isAwaitingConfirmation = (e: any) => {
    return e.row.data.transactionConfirmationStatus == ConfirmationStatus.WaitingForConfirmation && e.row.data.isDeleted === 'false';
  }

  selectionChangedHandler = (e: any) => {
    this.data.forEach(d => {
      d.__item__.selected = false;
    });
    this.selectedRows.forEach(row => {
      const index = this.gridItems.findIndex(data => data === row);
      this.data[index].__item__.selected = true;
    });
  }

  editBackground(e: any) {
    if (e.rowType == 'data') {
      if (e.data.isDeleted == 'true') {
        e.rowElement.style.textDecoration = 'line-through';
      }

      if (e.data.transactionConfirmationStatus != ConfirmationStatus.None && e.data.transactionConfirmationStatus != ConfirmationStatus.Disabled) {
        var backgroundColor = '#FFFFFF'
        // the plus character forces the enum to compare as number,
        // because trying to use enums as a switch/case parameter is a good reminder that under the fancy typescript hood this is still all javascript fuckery
        switch (+e.data.transactionConfirmationStatus) {
          case ConfirmationStatus.WaitingForConfirmation:
            backgroundColor = '#FF9955'; // orange
            break;
          case ConfirmationStatus.Rejected:
            backgroundColor = '#FF8888'; // red
            break;
          case ConfirmationStatus.Confirmed:
            backgroundColor = '#71B74B'; // green
            break;
        }

        e.rowElement.style.background = backgroundColor;
        e.rowElement.className = e.rowElement.className.replace("dx-row-alt", ""); // needed because alternate rowcolors override our custom rowcolor
        e.rowElement.className += " inverted";
      }
    }
  }
}
