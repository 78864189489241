import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';

import { TicketPrinter } from '../models/ticket-printer';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class TicketPrinterService {
  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getTicketPrinters(auctionClusterId: number): Observable<Array<TicketPrinter>> {

    const url = this.apiPath + '/' + auctionClusterId + '/ticketprinter';
    return this.webApiService.getList(url);
  }

  getTicketPrinter(auctionClusterId: number, ticketPrinterId: number): Observable<TicketPrinter> {
    const url = this.apiPath + '/' + auctionClusterId + '/ticketprinter/' + ticketPrinterId;
    return this.webApiService.getList(url);
  }

  save(ticketPrinter: TicketPrinter): Observable<any> {
    const url = this.apiPath + '/' + ticketPrinter.auctionClusterId + '/ticketprinter';
    return this.webApiService.save(url, ticketPrinter);
  }

  edit(ticketPrinter: TicketPrinter): Observable<any> {
    const url = this.apiPath + '/' + ticketPrinter.auctionClusterId + '/ticketprinter';
    return this.webApiService.edit(url, ticketPrinter.ticketPrinterId, ticketPrinter);
  }

  delete(ticketPrinterId: number, auctionClusterId: number): Observable<any> {
    const url = this.apiPath + '/' + auctionClusterId + '/ticketprinter';
    return this.webApiService.delete(url, ticketPrinterId);
  }

  testTicket(ticketPrinter: TicketPrinter): Observable<any> {
    const url = this.apiPath + '/' + ticketPrinter.auctionClusterId + '/ticketprinter/printtestticket';
    return this.webApiService.save(url, ticketPrinter);
  }

  clearBuffer(ticketPrinter: TicketPrinter): Observable<any> {
    const url = this.apiPath + '/' + ticketPrinter.auctionClusterId + '/ticketprinter/clearbuffer';
    return this.webApiService.save(url, ticketPrinter);
  }
}
