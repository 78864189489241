import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

// components
import { ContentComponent } from '../../shared/content/content.component';

// models
import { AccessLevel, AdditionalMenuItem } from '../../shared/models/additional-menu-item';

// services
import { LanguageService } from '../../shared/services/language.service';

import { AuctionClusterService } from '../shared/services/auction-cluster.service';

@Component({
  selector: 'platform-content-component',
  templateUrl: './platform-content.component.html',
  styleUrls: ['./platform-content.component.scss']
})

export class PlatformContentComponent extends ContentComponent implements OnInit, OnDestroy {

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  menuItems: Array<AdditionalMenuItem> = [];
  subMenuItems: Array<AdditionalMenuItem> = [];

  constructor(
    injector: Injector,
    private auctionClusterService: AuctionClusterService,
    languageService: LanguageService,
    private titleService: Title,
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(res => {
      this.rtlEnabled = res;
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  ngOnInit() {
    this.sidebarFirstLogo = null; 
    this.sidebarSecondLogo = null;
    
    this.auctionClusterService.getAuctionClusters().subscribe(acs => {
      this.titleService.setTitle('Aucxis Auction');
      const favicon = document.getElementById('faviconchangable') as HTMLLinkElement;
      if (favicon) {
        favicon.href = "assets/images/emblem-color.png";
      }
      if (acs && acs.length > 0) {
        this.manageLocaleStorageLogoImages(acs[0].image, acs[0].secondLogo);
      } else {          
        this.removeLocalStorageLogoImages();
      }

      const firstLogo = localStorage.getItem('AUCXIS_SIDEBAR_LOGO');
      const secondLogo = localStorage.getItem('AUCXIS_SIDEBAR_SECOND_LOGO');

      if (firstLogo) {
        this.sidebarFirstLogo = firstLogo;
      }

      if (secondLogo) {
        this.sidebarSecondLogo = secondLogo;
      }
    });

    super.ngOnInit();

    this.additionalMenuItemsService.getPublicMenuItems().subscribe(result => {

      if (result !== undefined){
        result.forEach( item => {
          if (item.accessLevels.length > 0){
            item.accessLevels.forEach(_ => {
              if (_ === AccessLevel.PLATFORM) {
                if (item.submenuId !== null) {
                  this.subMenuItems.push(item);
                } else this.menuItems.push(item);
              }
            })
          }
        })

        this.menuItems.sort((a, b) => {
          if (a.orderNumber < b.orderNumber) {
            return -1;
          }
          if (a.orderNumber > b.orderNumber) {
            return 1;
          }
          return 0;
        })

        this.subMenuItems.sort((a, b) => {
          if (a.orderNumber < b.orderNumber) {
            return -1;
          }
          if (a.orderNumber > b.orderNumber) {
            return 1;
          }
          return 0;
        })
      }
    },
      error => {
        this.errorService.show(error);
      });
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }
}
